<template>
  <div id="upload-demo">
    <div v-if="imageUrl" class="image-item">
      <el-image :src="imageUrl" />
      <div class="mask">
        <el-tooltip :content="$t('You need to re-upload after deleting')" placement="top" effect="light">
          <i class="el-icon-delete" @click="deleteImage"></i>
        </el-tooltip>
      </div>
    </div>
    <el-upload v-else drag :action="uploadUrl" name="attach" :on-success="uploadSuccess" :on-error="uploadErroe" :on-change="beforeAvatarUpload" :limit="1" :show-file-list="false" ref="upload" :file-list="fileList">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{$t('Drag the file here or')}} <em>{{$t('click Upload')}}</em><br>{{$t('Only JPG/PNG files can be uploaded and no more than 500KB')}}</div>
    </el-upload>
  </div>
</template>

<script>
import uploading from '../api/uploading'
export default {
  props: ['successCallback', 'failCallback', 'deleteCallback', 'imgurl'],
  data () {
    return {
      imageUrl: "",
      fileList: []
    }
  },
  computed: {
    uploadUrl () { return uploading.uploadImg() },
  },
  methods: {
    uploadSuccess (res) {
      if (res.code != 200) {
        this.fileList = [];
        this.$notify.error((this.$t(res.msg || '400001:upload failed, unsupported suffix')) + res.code)
        this.$message.error((this.$t(res.msg || '400001:upload failed, unsupported suffix')) + res.code)
      }
      this.imageUrl = res.data
      if (this.successCallback) this.successCallback(res.data)
      // this.imageUrl = require('../assets/image/Logo/logo.png');
    },
    uploadErroe (err) {
      if (this.failCallback) this.failCallback(err)
    },
    deleteImage () {
      this.imageUrl = '';
      if (this.deleteCallback) this.deleteCallback();
    },
    beforeAvatarUpload (file) {

      const isJPG = file.raw.type.indexOf('JPG', 'PNG');
      if (!isJPG) {
        this.$message.error(this.$t('--400001:upload failed, unsupported suffix'));
      }

      return isJPG
    }
  },
  mounted () {
    this.imageUrl = this.imgurl
  }
}
</script>

<style lang="less">
#upload-demo {
  .el-upload {
    width: 100%;
  }
  .image-item,
  .el-upload-dragger {
    width: 100%;
    height: 14vw;
    overflow: hidden;
    text-align: center;
  }
  .el-form-item__content {
    line-height: initial !important;
  }
  .image-item {
    position: relative;
    border: 1px dashed #333;
    .el-image {
      height: 100%;
    }
    .mask {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: #000000;
      opacity: 0;
      transition: opacity 500ms;
      color: #fff;
      i {
        margin-top: 20%;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
  .image-item:hover {
    .mask {
      opacity: 0.65;
    }
  }
}
</style>