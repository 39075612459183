<template>
  <div class="print-credentials">
    <div class="print-content" style="margin:30px 0;" id="printTest1">
      <div style="overflow:hidden;width:260px;margin:auto;">
        <el-image style="width:45px;float:left" :src="require('../assets/image/Logo/logo.png')"></el-image>
        <h1 style="float:left;font-size:26px;margin-top:0;line-height:40px;">{{title||'BNIF-AFUWA'}}</h1>
      </div>
      <div v-for="(v,k) in printParam" :key="k" :style="p">
        <div :style="key">
          <div>{{$t(k)}}</div>
        </div>
        <div :style="value" v-if="k=='dealType'">
          <div style="line-height:20px;">{{$t(v)}}</div>
        </div>
        <div :style="value" v-else>
          <div style="line-height:20px;">{{v}}</div>
        </div>
      </div>
      <p style="text-align: right;">{{loginInfo.name}} {{loginInfo.surname}}</p>
      <p style="text-align: right;">{{$manba().format('ymdhms')}}</p>
    </div>
    <div class="print-btn">
      <el-button size="mini" @click="openCallback">{{$t('close')}}</el-button>
      <el-button type="primary" size="mini" v-print="printConfig">{{$t('printrRceipt')}}</el-button>
    </div>
  </div>
</template>
<script>
// import country from '@/api/country'
export default {
  name: "printCredentials",
  props: ["param", "title", "isClosePrint"],
  data () {
    return {
      printConfig: {
        id: "printTest1",
        openCallback (vue) {
          vue.printLoading = false;
          vue.openCallback(vue)
        }
      },
      p: 'border:1px solid #a7a7a7; overflow:hidden; padding:0 10px; margin:-1px 0 0;',
      key: 'float:left; width: 150px; padding:5px 0; border-right: 1px solid #a7a7a7;',
      value: 'float:left; color:#ff0000; width:calc(100% - 161px); padding:5px 0; border-left:1px solid #a7a7a7; margin-left:-1px; padding-left:10px;',
    }
  },
  computed: {
    printParam () {
      if (!this.param) return {}
      console.log(this.param)
      let printParam = {
        transactionCode: this.param.transactionCode || this.param.remittorTransactionCode,
        remittorName: (this.param.remittorName || this.param.name) + ' ' + (this.param.remittorSurname || this.param.surname),
        payeeName: this.param.payeeName ? (this.param.payeeName + ' ' + this.param.payeeSurname) : undefined,
        idNumber: this.param.idCard || undefined,
        payeePhone: this.param.payeeLinkPhone || undefined,
        customerCode: this.param.customerCode || undefined,
        cardNo: this.param.cardNo || undefined,
        tradingHour: this.$manba(this.param.dateTime).format('ymdhms'),
        dealType: this.param.businessType,
        "Transaction tax": "₣ " + (this.param.taxAmount || 0),
        transactionAmount: "₣ " + (this.param.amount || 0),
        // serviceCharge: "₣ " + (this.param.purposeBefore || 0),
        "Service fee discount": "₣ " + (this.param.coupon || 0),
        "after discount service": "₣ " + (this.param.serviceChageAmout || 0),
        cardNoBalance: "₣ " + (this.param.cardNoBalance || this.param.remittorCardNoBalance || 0),

        operator: this.param.createId,
        operateOrg: this.param.orgInfoName
      }

      let plist = {};
      for (let k in printParam) {
        if (printParam[k]) plist[k] = printParam[k]
      }
      console.log(this.param)
      return plist;
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
  },
  methods: {
    //打印阅览框打开时回调
    openCallback () {
      this.$emit("update:isClosePrint", !this.isClosePrint);
    },
    /**调用父组件清空信息*/
    clearFatherNodeData () {
      this.$parent.clearData()
    },
  },
}
</script>

<style lang="less" scoped>
.print-credentials {
  margin: -50px 0 0;
  .print-btn {
    text-align: right;
    margin-top: 30px;
  }
}
</style>