<template>
  <el-card class="my-card  individual-account-form" shadow="never">
    <el-steps :active="stepsActive" align-center finish-status="success">
      <el-step :title="$t('inputInfo')"></el-step>
      <el-step :title="$t('uploadImage')"></el-step>
      <el-step :title="$t('accomplish')"></el-step>
    </el-steps>

    <el-form :model="openAccountForm" ref="openAccountForm" :rules="rules" label-position="top" class="have-steps">
      <!-- 第一步填写信息 -->
      <el-row :gutter="20" v-if="stepsActive===0">
        <el-col :span="6">
          <el-form-item :label="$t('loginAccount')" prop="loginName">
            <el-input size="medium" v-model="openAccountForm.loginName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('loaginPassword')" prop="loginPassword">
            <el-input size="medium" v-model="openAccountForm.loginPassword"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('firstName')" prop="name">
            <el-input size="medium" v-model="openAccountForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('lastName')" prop="surname">
            <el-input size="medium" v-model="openAccountForm.surname"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('idNumber')" :prop="business.type==6?'':'idCard'">
            <el-input size="medium" v-model="openAccountForm.idCard"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('sex')" :prop="business.type==6?'':'gender'">
            <el-select size="medium" v-model="openAccountForm.gender">
              <el-option :label="$t('sex1')" :value='1'></el-option>
              <el-option :label="$t('sex0')" :value='0'></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span=" 6">
          <el-form-item :label="$t('phone')" prop="phoneNo">
            <el-input size="medium" v-model="openAccountForm.phoneNo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('maritalStatus')" :prop="business.type==6?'':'marryStatus'">
            <el-select size="medium" v-model="openAccountForm.marryStatus">
              <el-option :label="$t('maritalStatus0')" :value='0'></el-option>
              <el-option :label="$t('maritalStatus1')" :value='1'></el-option>
              <el-option :label="$t('maritalStatus2')" :value='2'></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="`${$t('homeCountry')} / ${$t('provincial')} / ${$t('city')}`" prop="country_region_city">
            <el-cascader size="medium" v-model="openAccountForm.country_region_city" :options="countryTree" :props="format"></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('detailedAddress')" prop="address">
            <el-input size="medium" v-model="openAccountForm.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="business.type==6">
          <el-form-item :label="$t('businessLicenseCode')">
            <el-input size="medium" v-model="openAccountForm.businessLicenseNo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('uniekeId')" prop="phoneId">
            <el-input size="medium" v-model="openAccountForm.phoneId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('alternatePhone')" prop="phoneNoBak1">
            <el-input size="medium" v-model="openAccountForm.phoneNoBak1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('alternatePhone')" prop="phoneNoBak2">
            <el-input size="medium" v-model="openAccountForm.phoneNoBak2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('remark')" prop="remark">
            <el-input size="medium" v-model="openAccountForm.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 第二步上传图片 -->
      <el-row :gutter="20" v-if="stepsActive===1">
        <el-col :span="8">
          <el-form-item :label="$t('idCardFront')" prop="idImgFront">
            <upload-img :success-callback="idImageFrontSuccess" :delete-callback="idImageFrontDelete" :imgurl="openAccountForm.idImg" />
            <el-input size="medium" type="hidden" v-model="openAccountForm.idImgFront"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('idCardBack')" prop="idImgBack">
            <upload-img :success-callback="idImageBackSuccess" :delete-callback="idImageBackDelete" :imgurl="openAccountForm.idImg" />
            <el-input size="medium" type="hidden" v-model="openAccountForm.idImgBack"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="business.type==6">
          <el-form-item :label="$t('businessLicense')" prop="businessLicensePic">
            <upload-img :success-callback="blpSuccess" :delete-callback="blpDelete" :imgurl="openAccountForm.businessLicensePic" />
            <el-input size="medium" type="hidden" v-model="openAccountForm.businessLicensePic"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="stepsActive>=3" class="accomplish">
        <!-- 成功 -->
        <el-progress type="circle" v-if="stepsActive==3" :stroke-width="10" :percentage="percentage" :width="200" status="success"></el-progress>
        <div v-if="stepsActive==3" style="text-align:center;color:#67C23A;font-size:34px">{{$t('operateSuccessfully')}}<br></div>
        <!-- 失败 -->
        <el-progress type="circle" v-if="stepsActive==4" :stroke-width="10" :percentage="percentage" :width="200" status="exception"></el-progress>
        <div v-if="stepsActive==4" style="text-align:center;color:#F56C6C;font-size:34px;line-height: 50px;">
          {{$t('operationFailure')}}<br>{{failingMsg}}
        </div>
      </div>
    </el-form>

    <div class="bottom-button">
      <el-button size="medium" v-if="stepsActive>0&&stepsActive<2" @click="--stepsActive">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive==4" @click="stepsActive=1">{{$t('prevStep')}}</el-button>
      <el-button size="medium" v-if="stepsActive<1&&stepsActive<2" @click="nextStep('openAccountForm')">{{$t('nextStep')}}</el-button>

      <el-button size="medium" type="primary" v-if="stepsActive==1" @click="submitForm('openAccountForm')">{{$t('submit')}}</el-button>

      <el-button size="medium" type="primary" v-if="stepsActive==3" @click="printVisible=true">{{$t('printrRceipt')}}</el-button>
    </div>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </el-card>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import printCredentials from '@/components/printCredentials'

import verification from '@/assets/uilt/verification'
import customer from '@/api/customer'
export default {
  props: ['business'],
  components: { uploadImg, printCredentials },
  data () {
    return {
      stepsActive: 0,
      openAccountForm: {},
      failingMsg: "",
      percentage: 0,
      printVisible: false,
      printParam: null
    }
  },
  computed: {
    rules () {
      let loginNameCheck = (rule, value, callback) => {
        if (!value || value.length < 1) { return callback(new Error(this.$t('requiredFields'))); }
        this.loginNameCheck(value, callback);
      };
      let phoneNoCheck = (rule, value, callback) => {
        if (!value || value.length < 1) { return callback(new Error(this.$t('requiredFields'))); }
        this.phoneNoCheck(value, callback);
      };

      let v = new verification(this);

      return {
        loginName: [{ validator: loginNameCheck, required: true, trigger: 'blur' }],
        loginPassword: [{ required: true, validator: v.password, trigger: 'blur' }],
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        surname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        idCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        gender: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        phoneNo: [{ validator: phoneNoCheck, required: true, trigger: 'blur' }],
        marryStatus: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        country_region_city: [{ type: "array", required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        address: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        businessLicenseNo: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        idImgFront: [{ required: true, message: this.$t('pleaseUploadPictures'), trigger: 'blur' }],
        idImgBack: [{ required: true, message: this.$t('pleaseUploadPictures'), trigger: 'blur' }],
        businessLicensePic: [{ required: true, message: this.$t('pleaseUploadPictures'), trigger: 'blur' }],
      }
    },
    format () { return this.$store.state.format.cascader },
    countryTree () { return this.$store.state.globalParameter.countryTree; }
  },
  methods: {
    idImageFrontSuccess (imgurl) {
      console.log(imgurl)
      this.$set(this.openAccountForm, 'idImgFront', imgurl);
    },
    idImageFrontDelete () { this.openAccountForm.idImg = ''; },

    idImageBackSuccess (imgurl) { this.$set(this.openAccountForm, 'idImgBack', imgurl); },
    idImageBackDelete () { this.openAccountForm.idImg = ''; },

    blpSuccess (imgurl) { this.$set(this.openAccountForm, 'businessLicensePic', imgurl); },
    blpDelete () { this.openAccountForm.businessLicensePic = ''; },

    /**下一步时验证时候填写必填项 */
    nextStep (formName) {
      let _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) ++_this.stepsActive;
        else return false
      })
    },
    submitForm (formName) {
      let _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.stepsActive = 2
          let crc = _this[formName].country_region_city;
          _this[formName].country = crc[0];
          _this[formName].region = crc[1];
          _this[formName].city = crc[2];
          _this[formName].idImg = _this[formName].idImgFront + ',' + _this[formName].idImgBack;

          customer.openAccount({
            param: _this[formName],
            success: res => {
              if (res.code == 200) {
                _this.$message.success(_this.$t('success'))
                _this.stepsActive = 3;
                _this.printParam = res.data;
                _this.printVisible = true;
              }
              else {
                _this.stepsActive = 4;
                _this.failingMsg = _this.$t(res.msg);
                _this.$message.warning(_this.$t('warning') + _this.$t(res.msg));
              }
              _this.percentage = 100;
            }
          })
        }
        else return false;
      })
    },
    /**检查手机号是否被注册 */
    phoneNoCheck (phoneNo, callback) {
      customer.checkCustomerPhoneNo({
        param: { phoneNo: phoneNo },
        success: res => {
          if (res.code != 200) return callback(new Error(this.$t(res.msg)));
          else return callback();
        }
      })
    },
    /**检查账户是否被注册 */
    loginNameCheck (loginName, callback) {
      customer.checkCustomerLoginName({
        param: { loginName: loginName },
        success: res => {
          if (res.code != 200) return callback(new Error(this.$t(res.msg)));
          else return callback();
        }
      })
    }
  },
  mounted () {
    this.openAccountForm = {
      businessCode: this.business.businessCode,
      type: this.business.type
    }
  }
}
</script>

<style>
</style>